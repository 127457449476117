.grid_wrapper {
    /* outline: 1px dashed #80808040; */
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    /* pointer-events: none; */
}

.page_setting_btn {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background: royalblue;
    border-radius: 5px 0px 0px 5px;
    padding: 5px;
    color: white;
    opacity: 0.3;
}

.page_setting_btn:hover {
    opacity: 1;
    cursor: pointer;
}
