.img_wrapper{
	position: relative;
}

.img_overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: transparent;
	font-size: 2em;
	cursor: pointer;
	transition: 0.3s;
}

.img_overlay:hover{
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
	backdrop-filter: blur(3px);
}