.pan_h {
    animation: panning_h 50s infinite linear;
}

.pan_no {
}

.pan_very_slow {
    animation: panning_h 250s infinite linear;
}

.pan_slower {
    animation: panning_h 100s infinite linear;
}

.pan_slow {
    animation: panning_h 70s infinite linear;
}

.pan_normal {
    animation: panning_h 50s infinite linear;
}

.pan_fast {
    animation: panning_h 30s infinite linear;
}

.pan_faster {
    animation: panning_h 15s infinite linear;
}

@keyframes panning_h {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.img_pan:hover {
    animation: img_panning 14s infinite linear;
}

@keyframes img_panning {
    0% {
        object-position: top;
    }
    50% {
        object-position: bottom;
    }
    100% {
        object-position: top;
    }
}
