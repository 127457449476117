.panelSection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    margin-top: 0px;
}

.panelItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    user-select: none;
    padding: 10px;
    padding-top: 15px;
    border-radius: 3px;
    background-color: white;
    color: rgb(97, 97, 97);
    opacity: 1;
    position: relative;

    &:hover {
        // box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.16);
        cursor: grab;
        color: black;
    }
}

.panelItem svg {
    width: auto;
    height: 25px;
    fill: transparent;
    stroke: #c6c6c6;
}

.panelItem:hover svg path {
    // fill: royalblue;
    stroke: royalblue;
}

.panelItemIcon {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.3;
    color: #ffff0085;
}

.panelItem:hover .panelItemIcon {
    opacity: 1;
    color: yellow;
}

.ghostElement ~ div {
    transform: none !important;
}

@media only screen and (max-width: 768px) {
    .panelSection {
        width: 100%;
        // width: calc(100% + 30px);
        // margin-left: -15px;
        // margin-right: -15px;
        // border: 3px dashed red;
        padding-left: 15px;
        padding-right: 15px;
        overflow-x: scroll;
        height: 80px;
        display: flex;
        gap: 10px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .panelSection::-webkit-scrollbar {
        display: none;
    }

    .panelItem {
        min-width: 100px;
    }
}
