.main_heading {
    font-size: 1.2rem;
    font-weight: bold;
    background-color: royalblue;
    color: white;
    padding: 5px 15px;
}

.panelSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.panelItem {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    cursor: grab;
}

.panelItem .item_type{
	font-size: 0.9rem;
	color: #777;
	font-style: italic;
}
