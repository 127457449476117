.qform {
    display: flex;
    flex-direction: column;
}

.qform label {
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
}

.qform label .optional {
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
}

.qform input,
.qform textarea {
    padding: 7px 12px;
    outline: none;
    border: 1px solid #05050588;
    margin-top: 4px;
    margin-bottom: 15px;
    font-size: 1.1rem;
    line-height: 1.6rem;
    background-color: transparent;
}

.qform button {
    align-self: flex-start;
    padding: 8px 24px;
    outline: none;
    border: none;
    color: white;
    font-size: 1.1rem;
    line-height: 1.6rem;
    background-color: royalblue;
    cursor: pointer;
}
