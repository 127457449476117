.q-bold {
    font-weight: bold;
}

.q-edit-delete-list-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
}

.q-edit-delete-list-item {
    padding: 8px 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
}

.q-edit-delete-list-item:hover {
    background-color: rgb(243, 243, 243);
}

.q-edit-delete-list-item-name {
    flex: 1;
}

.q-edit-delete-list-item-delete {
    color: rgb(190, 190, 190);
}

.q-edit-delete-list-item-edit {
    color: rgb(190, 190, 190);
}

.q-edit-delete-list-item-delete:hover {
    color: red;
}

.q-edit-delete-list-item-edit:hover {
    color: royalblue;
}

.checkerboard {
    background-image: linear-gradient(45deg, #e7e7e7 25%, transparent 25%),
        linear-gradient(-45deg, #e7e7e7 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #e7e7e7 75%),
        linear-gradient(-45deg, transparent 75%, #e7e7e7 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}
