.body_wrapper {
	display: flex;
	flex-direction: row;
	/* background-color: rgb(214, 233, 234); */
	background-color: black;
	height: 100vh;
}

.wrapper {
	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;

	flex: 1;
}

.box {
	/* border: 1px solid rgb(124, 124, 124);
    box-shadow: 35px 35px 70px #adadad, -35px -35px 70px #ffffff;
    background-color: white; */

	/* border-radius: 50px;
    background: #e0e0e0;
    box-shadow: 8px 8px 16px #cccccc, -8px -8px 16px #f4f4f4; */

	box-shadow:
		0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
}

@media only screen and (max-width: 700px) {
	.body_wrapper {
		flex-direction: column;
		height: 100vh;
	}
}