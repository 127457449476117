
.table{
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

.table th, .table td{
	/* border: 1px solid blue; */
	/* padding: 5px; */
}

.table .resize_handle{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 5px;
	cursor: ew-resize;
	background-color: #ccc;
	opacity: 0;
}

.table:hover .resize_handle{
	opacity: 1;
}