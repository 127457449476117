.wrapper {
    display: inline;
    position: relative;
}

.wrapper .popup {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
    z-index: 10;
}

.wrapper .popup a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
    border-right: 1px solid lightgrey;
}

.wrapper .popup button {
    border: none;
    background: transparent;
}

.wrapper .popup button:hover {
    color: rebeccapurple;
    cursor: pointer;
}
