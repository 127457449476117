.ui.primary.button,
.ui.primary.buttons .button {
  /* background-color: teal !important; */
  background-color: var(--q_color_primary) !important;
}

/* for backup */
:root {
  /* --q_color_primary: #0466c8; */
  --q_color_primary: #00998e;

 }
