.topWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.tabWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    box-shadow: 0px 9px 6px -9px rgba(171, 171, 171, 1);
}

.singleTabWrapper {
    background-color: white;
    box-shadow: 0px 9px 6px -9px rgba(171, 171, 171, 1);
}

.tab {
    color: black;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.body {
    flex: 1;
    max-height: 100%;
    overflow: auto;
}

.container {
    // background-color: rgb(255, 255, 255);
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 25px;
    // height: 100%;
    max-height: 100%;
    min-width: 250px;
    box-sizing: border-box;
    height: 400px;
    // background-color: aqua;
}

.optionBtn {
    padding: 10px 0;
    margin: 2px 0;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: white;
    cursor: pointer;
    border-radius: 3px;
    transition: 0.3s;

    display: flex;
    justify-content: space-between;
}

.optionBtn .optionBtnArrow {
    opacity: 0.1;
}

.optionBtn:hover {
    padding: 10px;
    background-color: #e6e6e6;
    margin: 2px 0px 2px -8px;
}

.optionBtn:hover .optionBtnArrow {
    opacity: 0.5;
}

.areaHead {
    padding: 10px 0;
    margin: 2px 0;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: white;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.backBtn {
    padding: 10px;
    background-color: white;
    cursor: pointer;
    border-radius: 3px;
}

.backBtn:hover {
    background-color: #e6e6e6;
}

.heading {
    // background-color: royalblue;
	background-color: var(--q_color_primary);
    color: white;
    margin: -15px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    padding: 5px;
}

.hbox {
    display: flex;
    flex-direction: row;
}

.stateBtnWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stateBtnWrapper button {
    width: 40%;
    padding: 10px 20px;
    border: none;
    outline: none;
    background-color: white;
    cursor: pointer;
}

.stateBtn {
    border-radius: 15px;
}

.stateBtnL {
    border-radius: 15px 0 0 15px;
}

.stateBtnR {
    border-radius: 0 15px 15px 0;
}

.propertiesList {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 50px;
    // align-items: flex-start;
}

@media only screen and (max-width: 768px) {
    .topWrapper {
        // flex-direction: row;
    }
    .tabWrapper {
        // transform: rotate(180deg);
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        background-color: #00000011;
    }
    .tab {
        display: flex;
        align-items: center;
    }
    .tab span {
        display: none;
    }
}
