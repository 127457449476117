:root {
    --box-border-radius: 12px; /* Define the border radius */
}

.wrapper {
    position: fixed;
    bottom: 5px;
    right: 15px;
    /* left: 50%;
    transform: translateX(-50%); */
    z-index: 100;

    /* background-color: rgba(255, 255, 255, 0.527); */
    background-color: white;
    -webkit-box-shadow: 4px 4px 18px -4px rgba(145, 145, 145, 0.5);
    -moz-box-shadow: 4px 4px 18px -4px rgba(145, 145, 145, 0.5);
    box-shadow: 4px 4px 18px -4px rgba(145, 145, 145, 0.5);
    backdrop-filter: blur(50px);
    /* overflow: hidden; */

    display: flex;
    flex-direction: column;

    /* border-radius: 25px 25px 0 25px; */
    border-radius: var(--box-border-radius) var(--box-border-radius) 0
        var(--box-border-radius);
    border: 2px solid rgba(0, 0, 0, 0.199);

    /* padding: 15px; */
    /* width: 300px;  being managed from code */
    height: auto;
    max-height: 90vh;
    /* overflow-y: auto; */
    transition: 0.3s;
    background-color: black;

    box-shadow: 2px 2px 15px -2px rgba(189, 189, 189, 1);
}

.header_wrapper {
    position: relative;
    height: 5px;
}

.header {
    color: white;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    cursor: pointer;
    background-color: black;
    width: 100px;
    border-radius: 15px;

    position: absolute;
    top: -13px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.trigger {
    padding: 5px;
    padding-bottom: 0;
}

.trigger img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content {
    flex: 1;
    /* border-radius: 25px 25px 0 0; */
    border-radius: var(--box-border-radius) var(--box-border-radius) 0
        var(--box-border-radius);
    transition: 0.3s;
    background-color: white;
    padding: 15px;
}
