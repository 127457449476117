a {
	color: black;
}

a:hover {
	color: black;
}

.ui.page.dimmer {
	z-index: 11000;
}

.q_hide_on_mobile {
	display: block;
}

.q_hide_on_tablet {
	display: block;
}

@media (max-width: 768px) {
	.q_hide_on_tablet {
		display: none;
	}
}

@media (max-width: 480px) {
	.q_hide_on_mobile {
		display: none;
	}
}

input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

// p::first-letter {
//     font-size: 200%;
//     color: #8a2be2;
// }

// -----------------------------------------------


// -----------------------------------------------




$bg-color: #f0f0f0;
$dot-color: rgb(161, 161, 161);

// Dimensions
$dot-size: 2px;
$dot-space: 22px;

.editor_back_pattern {
	// overflow: hidden;
	width: 100%;
	// min-width: 100%;
	height: auto;
	// height: 200vh;

	background: linear-gradient(90deg,
			$bg-color (
			$dot-space - $dot-size),
		transparent 1%) center,
		linear-gradient($bg-color (
			$dot-space - $dot-size),
		transparent 1%) center,
		$dot-color;
	background-size: $dot-space $dot-space;

	// position: relative;
	// border: 2px dashed red;
}