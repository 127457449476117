.grid3 {
    display: grid;
    grid-template-columns: 40px 40px 40px;
    row-gap: 5px;
    column-gap: 1px;
}

.grid4 {
    display: grid;
    grid-template-columns: 40px 40px 40px 40px;
    row-gap: 5px;
    column-gap: 1px;
}

.grid5 {
    display: grid;
    grid-template-columns: 40px 40px 40px 40px 40px;
    row-gap: 5px;
    column-gap: 1px;
}

.item {
    margin: 0;
}

.colorGrid {
    width: auto;
    display: grid;
    min-height: 40px;
    grid-template-columns: repeat(6, 1fr);
    border-radius: 3px;
    border: 1px solid #bbbbbb;
    overflow: hidden;
}

.fontSetView {
    border-radius: 3px;
    border: 1px solid #bbbbbb;
    padding: 20px 15px;
}

.fontSetViewSelected {
    outline: 3px solid rgb(140, 160, 219);
}

.fontSetView h3,
.fontSetView h2 {
    margin-bottom: 5px;
}
