.item{
    background-color: #ebecf0;
    border-radius: 10px;
    max-height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    cursor: pointer;
}

.icon{
    width: 50px;
    height: 50px;
    min-width: 50px;
    background-color: #9cbefc;
    border-radius: 10px 0 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: white;
}

.label{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 10px;
}