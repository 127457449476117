


.panelDraggable {
    // width: 300px;
    // min-height: 500px;
    max-height: 90%;
    position: absolute;
    margin: 5px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);;
    z-index: 50;
}

.header {
    padding: 2px;
    background-color: #e3e3e5;
    color: rgb(187, 187, 187);
    font-size: 1.7rem;
    text-align: center;
    cursor: move;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.dragHandle{
    flex: 1;
    padding: 7px;
    margin-left: 20px;
}

.foldedBtn{
    background-color: royalblue;
    color: white;

    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.foldedBtn p{
    padding-top: 10px;
    padding-left: 15px;
    font-weight: bold;
    cursor: move;
}
