:root {
    --bot-color: royalblue;
    --bot-message-radius: 15px;
}

.chips_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.suggestion_chip {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    padding: 7px 14px;
    border-radius: 15px;
    cursor: pointer;
    opacity: 0.7;
}

.suggestion_chip:hover {
    opacity: 1;
}

.chip_disabled {
    background-color: #eeeeee;
    opacity: 0.4;
    cursor: not-allowed;
}

.chip_disabled:hover {
    opacity: 0.4;
}

.chip_selected {
    opacity: 1;
}

.chip_selected:hover {
    opacity: 1;
}

.message_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}

.message {
    padding: 7px 14px 9px 14px;
    font-size: 1.1em;
}

.message_user {
    align-self: flex-end;
    border-radius: 15px 0 15px 15px;
    background-color: royalblue;
    color: white;
    margin-left: 25px;
}

.message_bot {
    align-self: flex-start;
    border-radius: 0 15px 15px 15px;
    border: 1px solid #999;
    margin-right: 25px;
    color: balck;
}

.input_box_wrapper {
    position: relative;
}

.input_box {
    width: 100%;
    padding: 7px 14px;
    margin-bottom: -5px;
    font-size: 1.1em;
    border: none;
    border: 1px solid #999;
    outline: none;
    border-radius: 12px;
}

.input_box:disabled {
    background-color: #e3e3e5;
}

.send_btn {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;

    border: none;
    outline: none;
    border-radius: 12px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.send_btn:disabled {
    background-color: #868686;
    cursor: not-allowed;
}

.single_input_box {
    width: 100%;
}

/* ------------------------------------------------- */

.single_input_box .single_input_label {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
}
