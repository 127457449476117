/* None */
.qureal-none {
}

/* Grow */
.qureal-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.qureal-grow:hover,
.qureal-grow:focus,
.qureal-grow:active {
    transform: scale(1.1);
}

/* Shrink */
.qureal-shrink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.qureal-shrink:hover,
.qureal-shrink:focus,
.qureal-shrink:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

/* Pulse */
@-webkit-keyframes qureal-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}
@keyframes qureal-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}
.qureal-pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.qureal-pulse:hover,
.qureal-pulse:focus,
.qureal-pulse:active {
    -webkit-animation-name: qureal-pulse;
    animation-name: qureal-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

/* Push */
@-webkit-keyframes qureal-push {
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes qureal-push {
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.qureal-push {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.qureal-push:hover,
.qureal-push:focus,
.qureal-push:active {
    -webkit-animation-name: qureal-push;
    animation-name: qureal-push;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

/* Pop */
@-webkit-keyframes qureal-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}
@keyframes qureal-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}
.qureal-pop {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.qureal-pop:hover,
.qureal-pop:focus,
.qureal-pop:active {
    -webkit-animation-name: qureal-pop;
    animation-name: qureal-pop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

/* Float */
.qureal-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.qureal-float:hover,
.qureal-float:focus,
.qureal-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
}

/* Bob */
@-webkit-keyframes qureal-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@keyframes qureal-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@-webkit-keyframes qureal-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@keyframes qureal-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
.qureal-bob {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.qureal-bob:hover,
.qureal-bob:focus,
.qureal-bob:active {
    -webkit-animation-name: qureal-bob-float, qureal-bob;
    animation-name: qureal-bob-float, qureal-bob;
    -webkit-animation-duration: 0.3s, 1.5s;
    animation-duration: 0.3s, 1.5s;
    -webkit-animation-delay: 0s, 0.3s;
    animation-delay: 0s, 0.3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
}

/* Forward */
.qureal-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.qureal-forward:hover,
.qureal-forward:focus,
.qureal-forward:active {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
}

/* Grow Shadow */
.qureal-grow-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}
.qureal-grow-shadow:hover,
.qureal-grow-shadow:focus,
.qureal-grow-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
