.wrapper{
    margin-top: 20px;
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 15px;
    row-gap: 15px;
}

.item{
    background-color: #ebecf0;
    border-radius: 10px;
    max-height: 60px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    cursor: pointer;
}

.icon{
    width: 60px;
    height: 60px;
    min-width: 60px;
    background-color: #9cbefc;
    border-radius: 10px 0 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: white;
}

.label{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 10px;
}

.selected{
    outline: 3px solid #4186fc
}