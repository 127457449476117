.qnav-lineBelow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}

.qnav-lineBelow:hover {
    border-bottom: 5px solid black;
}

.qnav-bubble:hover a {
    background-color: black;
    color: white;
}

.qnav-full:hover {
    background-color: black;
}
.qnav-full:hover a {
    color: white;
}

.qnav-border {
    border: 5px solid transparent;
}

.qnav-border:hover {
    border: 5px solid black;
}

.qnav-parda::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 0;
    background-color: aqua;
    z-index: -1;
    transition: 0.3s;
}
.qnav-parda {
    position: relative;
    z-index: 2;
}
.qnav-parda:hover:before {
    height: 100%;
}
