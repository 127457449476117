.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.item {
    padding: 3px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: stretch;

    /* justify-content: space-between; */
}

.right_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.actions_wrapper {
    display: flex;
    flex-direction: row;
}

.preview_og {
    width: 150px;
    height: 100px;
    object-fit: contain;
    background-color: #eee;
}

.info_wrapper p {
    margin: 2px;
    font-size: 0.9em;
}

.good {
    color: green;
}
