.button {
	display: inline-block;
	padding: 10px 20px;
	border-radius: 50px;
	background: linear-gradient(to right, #ffcc00, #ff00ff, #00ccff);
	background-size: 200% auto;
	color: #fff;
	font-weight: bold;
	text-decoration: none;
	transition: background-position 0.5s;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	border: none;

	transform: translateZ(0);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.button:hover {
	background-position: right center;
}

.button::before {
	content: "";
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 10%, transparent 20%);
	background-size: 1000% 1000%;
	animation: sparkle 1.5s linear infinite;
}

@keyframes sparkle {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}


.loading {
	animation: loadingAnimation .7s linear infinite;
	animation-direction: alternate;
}

@keyframes loadingAnimation {
	0% {
		background-position: 0% center;
	}
	100% {
		background-position: 100% center;
	}
}
