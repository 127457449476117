.imgarrWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 10px;
    cursor: pointer;
}

.imgWrapper {
    position: relative;
    outline: 1px solid #ddd;
    border-radius: 3px;
    overflow: hidden;
    height: 50px;
}

.imgWrapper img {
    position: absolute;
    height: 50px;
    min-height: 50px;
    width: 100%;
    background-color: #eee;
    object-fit: cover;
    overflow: hidden;
}

.imgWrapper .overlay {
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.541);
    opacity: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imgarrWrapper .overlay:hover {
    cursor: pointer;
    opacity: 1;
}

.imgarrWrapper .overlay i {
    color: white;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.list_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.list_item input,
.list_item label {
    cursor: pointer;
}

.btn_group {
    display: flex;
    flex-direction: row;
}

.up_down_wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 2px;
}

.up_down_btn {
    border: 1px solid #ccc;
    padding: 0 3px;
    line-height: 0.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #666;
}

.up_down_btn:hover {
    color: black;
}
