.wrapper {
    width: 800px;
    max-width: 100%;
    height: 100%;
    min-height: 50vh;
    max-height: 90vh;
    background-color: white;
    padding: 15px;
    padding-top: 10px;
    margin: 10px auto 20px auto;
    overflow: auto;
}

.fit_wrapper {
    width: 100%;
    height: 100%;
    min-height: 100px;
    background-color: transparent;
}
