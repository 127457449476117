.wrapper {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid rgb(202, 202, 202);
    user-select: none;
}

.db {
    color: gray;
    font-size: 1.3rem;
    background-color: #e0e1e2;
    margin: -7px;
    padding: 6px;
}

.content {
    flex: 1;
    padding-left: 15px;
    padding-right: 10px;
    font-weight: bold;
}

.table {
    background-color: rgb(182, 131, 38);
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 3px 0 0 3px;
    z-index: 3;
}

.field {
    background-color: orange;
    color: white;
    padding: 4px 12px 4px 8px;
    /* margin-left: -12px; */
    border-radius: 0px 20px 20px 0px;
    z-index: 1;
}

.close {
    color: gray;
    cursor: pointer;
    padding: 7px;
    margin: -7px;
}
